export const publicInstallLink = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_ZOOM_APP_SERVER_URL}/zoomapp/auth`

export const twineZoomAppMarketplaceUrl = 'https://marketplace.zoom.us/apps/-cYA3VcaQduxP8suHFGXiA'

export const PROXY_URL = process.env.REACT_APP_PROXY_URL

export const GRAPHQL_URL = `https://${process.env.REACT_APP_AMBIENT_API_HOSTNAME!}/graphql`
export const GRAPHQL_WS_URL = `wss://${process.env.REACT_APP_AMBIENT_API_HOSTNAME!}/graphql/subscription`
export const AMBIENT_API_URL = `https://${process.env.REACT_APP_AMBIENT_API_HOSTNAME!}`
export const AMBIENT_WEBSITE_URL = `${process.env.REACT_APP_PUBLIC_URL!}`
export const AMBIENT_FRONTEND_URL = `${process.env.REACT_APP_AMBIENT_APP_FRONTEND!}`

export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID

const LOCALTUNNEL = process.env.REACT_APP_ZOOM_APP_CLIENT_URL
export const CSP_liberal = `default-src *; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; font-src https://fonts.gstatic.com; script-src * 'self' 'unsafe-inline'; connect-src * 'self' https://${LOCALTUNNEL} wss ws; img-src 'self' *; base-uri 'self'; form-action 'self';`

export const WHITELISTED_HOST_USER_IDS: { [name: string]: string } = {
	'Brian Li (brian.li@gravit8.app)': 'S2ok8VxkRGGsKh1jDMbNyg'
}

export const HOST_ONLY_MODE_PARTICIPANT_LIMIT: number = 20

export const MINIMUM_SUPPORTED_ZOOM_VERSION: string = '5.10.6'

export const MAXIMUM_BREAKOUT_ROOMS: number = 50

export const MAXIMUM_BREAKOUT_ROOMS_WITH_LARGE_ADDON: number = 100

export const FAQ_URL =
	'https://help.twine.nyc/en/collections/3348690-twine-for-zoom?utm_source=product&utm_medium=banner&utm_campaign=help_tab'

// help resource URLs
export const AB_MATCHING_HELP_URL =
	'https://help.twine.nyc/en/articles/6331201-twine-for-zoom-mode-a-b-matching?utm_source=product&utm_medium=tooltip&utm_campaign=config'
export const MULTI_TAG_MATCHING_HELP_URL =
	'https://help.twine.nyc/en/articles/6331277-twine-for-zoom-mode-tag-based-matching?utm_source=product&utm_medium=tooltip&utm_campaign=config'
export const SPEED_NETWORKING_HELP_URL =
	'https://help.twine.nyc/en/articles/6331104-twine-for-zoom-mode-shuffle?utm_source=product&utm_medium=tooltip&utm_campaign=config'
export const VISUAL_BREAKOUTS_HELP_URL =
	'https://help.twine.nyc/en/articles/6027482-twine-for-zoom-mode-map-mode?utm_source=product&utm_medium=tooltip&utm_campaign=config'

export const SUBSCRIPTION_PRICING_URL: string =
	'https://www.twine.us/pricing?utm_source=product&utm_medium=banner&utm_campaign=startertier'

export const AMBIENT_LOGIN_URL: string =
	'https://www.twine.us/pricing?utm_source=product&utm_medium=banner&utm_campaign=startertier'
